<div class="curtain" id="some-div">
    <div class="curtain-wrapper" >
      <input  type="checkbox"   checked>
      <div class="curtain__panel curtain__panel--left center" data-title="Click to reveal a special reward..."  ><span id="some-element" class="text-right "><div class="pulse"></div></span><img class="img-fluid" src="assets/img/wido-hair-def-1.png"> </div>
      
      <div class="curtain__prize bg-black"  >
        <app-gallery-wido-hair></app-gallery-wido-hair>
      </div>
      <div class="curtain__panel curtain__panel--right center-start" data-title="Click to reveal a special reward..."><span id="some-element-2" class="text-left "></span><img class="img-fluid" src="assets/img/wido-hair-def-2.png"></div>
    </div>
  </div>




