
<div class="text-center my-5">
    <p class="text-dark lead pt-5">New styling experience | hair | concept shooting | art contamination | events<br><br><br><br> <br><br>
   <p>
    WIDO SRLS - Sede legale: Corso Mazzini 6, 27100 Pavia -  P.Iva: 02728440187 - N.REA: PV - 296897 - Capitale sociale: 10.000€ - Tel: 0382 32543 - info@widostudio.com - PEC: wido@pec.it
   </p>
</div>
<div class="text-center my-5">
    <a href="https://www.iubenda.com/privacy-policy/84173861" target="_blank" class="  text-dark iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe mx-3" title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<a href="https://www.iubenda.com/privacy-policy/84173861/cookie-policy"  target="_blank" class="text-dark iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe mx-3" title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
<!-- <a href="https://www.blu-system.com/" class=" text-dark mx-3" target="_blank">Powered by Blu System</a> -->
</div>


