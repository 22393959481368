<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="o5xiP00g"></script>
    <div class="fb-share-button btn btn-dark px-3 text-white" mat-button data-href="https://widostudio.com/page/staff" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fpage%2Fstaff&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore text-white" style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->

<div class="body" style=" padding-bottom: 15%;">
   

    <section class="container  lead  " >
        <div class="row"> 
        <div class="col-lg-6 py-5" style="text-align: -webkit-center;">
            <img src="/assets/img/staff/wido-studio-staff-Guido.png" class=" img-fluid" alt="Staff wido studio pavia">
            <h1 class="thick pt-4 text-left">WIDO<br><span  class="thin h5">Director</span></h1>
            <p class="thin text-left">
                <br><br>
                In continua ricerca della bellezza e delle novità. Appassionato di cinema, arte e design. 
                La sua specialità risiede nel creare look ad hoc per far risaltare ciascun cliente. 
            </p>
        </div>

    <div class="col-lg-6 py-5" style="text-align: -webkit-center;">
        <img src="/assets/img/staff/wido-studio-staff-Rossana.png" class=" img-fluid " alt="Staff wido studio pavia">
        <h1 class="thick pt-4 text-left">ROSSANA<br><span  class="thin h5">colorist/stylist</span></h1>
        <p class="thin text-left">
            <br><br>
            Minuziosa nel lavoro. Concentrata e precisa. 
            Il balayage è la sua specialità, soprattutto per le tonalità del biondo. 
            
        </p>
    </div>

<div class="col-lg-6 py-5" style="text-align: -webkit-center;">
    <img src="/assets/img/staff/wido-studio-staff-Chiara.png" class=" img-fluid " alt="Staff wido studio pavia">
    <h1 class="thick pt-4 text-left">CHIARA<br><span  class="thin h5">colorist/stylist</span> </h1>
    <p class="thin text-left ">
      <br><br>
      Solare e frizzante, tanto quanto minuziosa. Il suo sorriso è contagioso.
      Ricreare colorazioni dall’effetto naturale tramite gli highlights è il suo punto forte. 
      
    </p>
</div>

    <div class="col-lg-6 py-5" style="text-align: -webkit-center;">
        <img src="/assets/img/staff/wido-studio-staff-Martina.png" class=" img-fluid " alt="Staff wido studio pavia">
        <h1 class="thick pt-4 text-left">MARTINA <br><span  class="thin h5"> colorist/men stylist</span></h1>
        <p class="thin text-left">
           <br><br>
           Gentilezza e garbo sono le qualità che la contraddistinguono. Esperta in creare look sobri e raffinati, dal risultato semplice ma deciso. Appassionata di hair style maschile. 
        </p>

    </div>

    <div class="col-lg-6 py-5" style="text-align: -webkit-center;">
        <img src="/assets/img/staff/wido-studio-staff-Andrea.png" class="img-fluid" alt="Staff wido studio pavia">
        <h1 class="thick pt-4 text-left">ANDREA <br><span  class="thin h5">colorist/stylist</span></h1>
        <p class="thin text-left">
            <br><br>
            Empatica e brillante, perfetta per una clientela giovane e dinamica. 
            Specializzata in balayage, schiariture e tinte. 
            
            
        </p>

    </div>

<div class="col-lg-6 py-5" style="text-align: -webkit-center;">
    <img src="/assets/img/staff/wido-studio-staff-Angelica.png" class=" img-fluid" alt="Staff wido studio pavia">
    <h1 class="thick pt-4 text-left">ANGELICA <br><span  class="thin h5">nail artist</span></h1>
    <p class="thin text-left">
<br><br>
Precisone e creatività, doti fondamentali nel suo lavoro che lei incarna appieno. Esperta in nail art, laminazione ciglia e cura delle sopracciglia. 
    </p>
</div>

    <div class="col-lg-6 py-5" style="text-align: -webkit-center;">
        <img src="/assets/img/staff/wido-studio-staff-Alberto.png" class=" img-fluid " alt="Staff wido studio pavia">
        <h1 class="thick pt-4 text-left">ALBERTO<br><span  class="thin h5">make-up artist</span></h1>
        <p class="thin text-left ">
          <br><br>
          Fantasioso ed entusiasta del suo lavoro. Ama valorizzare i lineamenti del viso, in particolar modo le labbra, per ottenere un effetto unico. 

    </div>

<div class="col-lg-6 py-5" style="text-align: -webkit-center;">
    <img src="/assets/img/staff/wido-studio-staff-Mary.png" class=" img-fluid " alt="Staff wido studio pavia">
    <h1 class="thick pt-4 text-left">MARY<br><span  class="thin h5">photographer</span></h1>
    <p class="thin text-left">
        <br><br>
        Amante delle arti e della fotografia, coinvolge tutti con il suo entusiasmo e la sua creatività. Il suo stile glamour e patinato rende i suoi scatti delle vere e
proprie opere d'arte.

    </p>
</div>


<div class="col-lg-6 py-5" style="text-align: -webkit-center;">
    <img src="/assets/img/staff/wido-studio-staff-Silvia.png" class=" img-fluid " alt="Staff wido studio pavia">
    <h1 class="thick pt-4 text-left">SILVIA<br><span  class="thin h5">receptionist</span> </h1>
    <p class="thin text-left">
        <br><br>
        Paziente e attenta ai dettagli.

Appassionata all’arte, alla fotografia e alla cura della persona. 
Accoglie la clientela del salone e la fa sentire come a casa.

    </p>
</div>
</div>

    </section>
    
  
    </div>
