<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="Yu6SuoGv"></script>
    <div class="fb-share-button btn btn-dark px-3  " mat-button data-href="https://widostudio.com/wido-beauty" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fwido-beauty&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore text-white " style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->

<div class="body text-center">
    <section class="container    lead mb-5 pb-5 "  >
        <div class="col-lg-12 text-center mt-5 py-5">
            <h1 class="thick text-uppercase">Manicure con smalto normale</h1>
            <p class="thin ">
                La manicure è il trattamento per eccellenza per avere mani e unghie curate.<br>
I benefici di una manicure eseguita da un professionista non si limitano al semplice aspetto esteriore delle mani. Infatti, la manicure svolta regolarmente permette di esfoliare e idratare la pelle, migliorando la salute generale delle unghie e delle mani, prevenendo la formazione di disturbi, tra cui i funghi.
<br><br>
Il trattamento completo prevede anche l’applicazione dello smalto classico con svariati colori tra cui scegliere.<br><br>

               <span  style="font-weight: 900;">Costo: 20€ </span>
            </p>
        </div>
       
        <div class="col-lg-12 text-center py-5">
            <h1 class="thick text-uppercase">Smalto semipermanente</h1>
            <p class="thin ">
                L’applicazione dello smalto semipermanente, una particolare tipologia di smalto fotoindurente, garantisce unghie in ordine per almeno 2 settimane.<br> 
                La rapidità dell’applicazione, la facilità con cui viene rimosso in salone e la lunga durata, rappresentano fattori determinanti nella scelta di questa specifica manicure. 
                <br><br>
               <span  style="font-weight: 900;"> Durata</span> : 30 minuti (45/60 minuti se è da effettuare anche la rimozione dello smalto precedente)<br><br>
               <span  style="font-weight: 900;">Costo con manicure</span> :  €35<br><br>
               <span  style="font-weight: 900;">Costo della sola rimozione</span> :  €10
            </p>
        </div>


        <div class="col-lg-12 text-center py-5">
            <h1 class="thick text-uppercase">RICOSTRUZIONE UNGHIE o COPERTURA IN GEL DELLE UNGHIE NATURALI</h1>
            <p class="thin">
                Questo determinato servizio è indicato per chi desidera  una maggior resistenza delle unghie agli urti e all’usura, rispetto alla sola applicazione dello smalto semipermanente. <br>

                Infatti, la ricostruzione garantisce mani sempre in ordine, per un periodo di tempo più lungo, di circa 4 settimane. <br>
                Inoltre è un buon metodo per combattere il vizio di mangiare le unghie. <br><br>
                
                Ci sono vari metodi e prodotti per ricostruire le unghie e Wido Beauty ha scelto di utilizzare l’acrygel, che permette di ottenere un effetto naturale, grazie al suo alto grado di flessibilità e resistenza. <br>
                
                In salone si possono effettuare ricostruzioni di qualsiasi forma, lunghezza, colore e nail art. 
                <br><br>
                <span  style="font-weight: 900;"> Durata</span> : 1 ora e 30 minuti circa, a seconda della lunghezza e della nail art richiesta <br><br>
                <span  style="font-weight: 900;">Costo Ricostruzione</span> : €70<br><br>
                <span  style="font-weight: 900;">Costo Copertura in gel</span> : €45<br><br>
                <span  style="font-weight: 900;">Costo refill</span> : €55<br><br>
            </p>
        </div>
    
    </section>
    <div class="row m-0  ">
 
    <div class="col-lg-6" style="text-align: -webkit-center;"> 
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-beauty-pavia-2.jpg" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text">CONTEMPORANEO</span>
                <div class="icon">
                         
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-beauty-pavia-2.jpg" alt="info image">
                </div>
            </div>
            
        </div>      
    </div>
    <div class="col-lg-6" style="text-align: -webkit-center;"> 
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-beauty-pavia-4.png" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text TEXT-UPPERCASE">COMPLESSO</span>
                <div class="icon">
                      
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-beauty-pavia-4.png" alt="info image">
                </div>
            </div>
            
        </div>      
    </div>
    </div>

    

        <section class="container   lead  pb-3" style=" margin-bottom: 15%;">
            <div class="col-lg-12 py-5">
                <h1 class="thick text-uppercase">Depilazione sopracciglia:</h1>
                <p class="thin ">
                    Le sopracciglia sono la parte del viso che più definisce la nostra espressione, la forma del viso e le proporzioni di occhi e naso. Per questo motivo, Wido Beauty ricerca la miglior forma per le sopracciglia di ogni cliente, attraverso l’utilizzo della depilazione con ceretta classica e pinzetta per rifinire. 

              <br><br>      Il trucco finale per disegnare l’arcata viene usato solo se necessario. 
              <br><br>
                    <span  style="font-weight: 900;">Durata: 15 minuti<br><br></span>
                    <span  style="font-weight: 900;">Costo: 22€</span>
                   
                </p>
            </div>
        
            <div class="col-lg-12 py-5">
                <h1 class="thick text-uppercase">Tinta semipermanente sopracciglia:</h1>
                <p class="thin ">
                    La tinta semipermanente consente di ottenere un effetto naturale a lunga durata. Simile a un tatuaggio, il trucco semipermanente si esegue con l’introduzione di micropigmenti minerali, totalmente scuri, nel Derma Basale, grazie al Dermografo.

                <br><br>La sua resistenza è soggettiva e dipende molto da persona a persona. Generalmente le prime volte dura all’incirca 2 settimane, se poi il trattamento è eseguito con regolarità tutti i mesi, diventa sempre più persistente. È adatta a tutte le tipologie di sopracciglia, sia quelle più sottili per renderle più voluminose, che quelle più folte per definirle al meglio. 
                <br><br>
                    <span  style="font-weight: 900;">Durata</span>: 15 minuti <br><br>
                    <span  style="font-weight: 900;">Costo: 15€</span>
                    
                </p>
            </div>
            <div class="col-lg-12 py-5">
                <h1 class="thick text-uppercase">Trattamento sopracciglia: depilazione + tinta</h1>
                <p class="thin ">
                    Questo trattamento combinato permette di avere sopracciglia in ordine e definite per circa 4 settimane.<br><br> 
                    <span  style="font-weight: 900;">Durata </span>: 30 minuti<br><br>
                    <span  style="font-weight: 900;">Costo: 32€ </span>
                </p>
            </div>
    
            <div class="col-lg-12 py-5">
                <h1 class="thick text-uppercase">LAMINAZIONE + Eventuale tinta SOPRACCIGLIA</h1>
                <p class="thin ">
                    La laminazione delle sopracciglia è forse il trattamento più completo per avere sopracciglia in ordine per un periodo di tempo superiore. 
                    L’alta intensità di idratazione ne favorisce l’infoltimento e conferisce una piega definita anche ai peli più corti. <br><br>
                    
                    La durata della laminazione per le prime applicazioni è di 4/5 settimane, ma diventa più persistente con la ripetizione regolare del trattamento.<br>
                    
                    Il trucco finale per disegnare l’arcata viene usato solo se necessario. <br><br>
                    
                    <span  style="font-weight: 900;">Durata </span>: 45 minuti<br><br>
                    <span  style="font-weight: 900;">Costo: 52€ </span>
                </p>
            </div>
            <div class="col-lg-12 py-5">
                <h1 class="thick text-uppercase">LAMINAZIONE + Eventuale tinta CIGLIA </h1>
                <p class="thin ">
                    La laminazione delle ciglia è un trattamento estetico che giova alla salute delle ciglia stesse, idratandole e ispessendole. Il risultato che si ottiene è uno sguardo più inteso e valorizzato, ma molto naturale. <br>

                    È adatta sia alle ciglia che crescono troppo dritte o verso il basso, sia a quelle sfibrate o sottili che quelle più lunghe e rigide. Inoltre è un trattamento utile per coloro che stanno effettuando la pausa dall’extension ciglia. <br>
                    
                    Viene effettuata anche la tinta delle ciglia più chiare, al fine di renderle più accentuate. <br><br>
                    
                    La durata della laminazione per le prime applicazioni è di 4/5 settimane, ma diventa più persistente con la ripetizione regolare del trattamento, come per quanto riguarda le sopracciglia. <br><br>
                    
                    
                    <span  style="font-weight: 900;">Durata </span>: 1 ora<br><br>
                    <span  style="font-weight: 900;">Costo: 65€ </span>
                </p>
            </div>
          
        </section>
    </div>