<div style="text-align: -webkit-center; font-family: 'Lato', sans-serif !important;font-size: 1.9vh;">
  <a class="navbar-brand m-0 pt-5" href=" " style="background-color: white; max-width: 85px;">
    <img src="assets/img/wido-logo-official.png" class="img-fluid " style="padding-left: 5px; padding-right: 5px;">
</a>
<nav class="navbar navbar-expand-lg navbar-dark py-4 " style="background-color: #fff;">
    <button class="navbar-toggler" style="color: black;" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <!-- <div class="collapse navbar-collapse" style="place-content: center;" [ngbCollapse]="isMenuCollapsed" id="navbarNavDropdown">
      <div class="navbar-nav row" style="place-content: center;">
        <div class="nav-item col-lg-1">
          <a class="nav-link thin" [routerLink]="'/wido-studio'" style="color: black;" (click)="isMenuCollapsed = true">Studio</a>
        </div>
        <div class="nav-item col-lg-1">
          <a class="nav-link thin" [routerLink]="'/wido-hair'" style="color: black;" (click)="isMenuCollapsed = true">Hair</a>
        </div>
        <div class="nav-item col-lg-1">
            <a class="nav-link thin" [routerLink]="'/wido-beauty'" style="color: black;" (click)="isMenuCollapsed = true">Beauty</a>
          </div>
          <div class="nav-item col-lg-1">
            <a class="nav-link thin" [routerLink]="'/wido-shooting'" style="color: black;" (click)="isMenuCollapsed = true">Production</a>
          </div>
          <div class="nav-item col-lg-1">
            <a class="nav-link thin ml-lg-4" [routerLink]="'/page/staff'" style="color: black;" (click)="isMenuCollapsed = true" >Staff</a>
          </div>
        <div class="nav-item col-lg-1">
            <a class="nav-link thin" [routerLink]="'gallery'" style="color: black;" (click)="isMenuCollapsed = true" >Gallery</a>
          </div>
          <div class="nav-item col-lg-1">
            <a class="nav-link thin" [routerLink]="'/page/wido-contatti'" style="color: black;" (click)="isMenuCollapsed = true" >Contatti</a>
          </div>
          <div class="nav-item col-lg-1"  style="text-align: center;">
            <a class="nav-link thin" href="https://www.facebook.com/widostudiopavia" target="_blank" (click)="isMenuCollapsed = true"><img class="img-fluid w-social" src="/assets/img/wido-facebook.png"></a>
          </div>
          <div class="nav-item col-lg-1 "  style="text-align: center;">
            <a class="nav-link thin" href="https://www.instagram.com/wido_studio/" target="_blank" (click)="isMenuCollapsed = true"><img class="img-fluid w-social" src="/assets/img/wido-instagram.png"></a>
          </div>
        </div>
    </div> -->
  </nav> 
</div>


 
<!-- Open on hover menú -->

<!-- <div style="text-align: -webkit-center;">
  <a class="navbar-brand m-0 pt-5" href=" ">
    <img src="assets/img/wido-logo.png" class="img-fluid w-50">
</a>
<div class="menu-wrapper">
  <div class="menu"  style="text-align: left;">
      <ul  class="nav-links" style=" background-color: rgba(248, 248, 255, 0.692);"  >
        <li style=" background-color: rgba(248, 248, 255, 0.692);"  >
          <a class="text-white" [routerLink]="'/wido-studio'" (click)="isMenuCollapsed = true">Studio</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'/page/staff'" (click)="isMenuCollapsed = true" >Staff</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'/wido-hair'" (click)="isMenuCollapsed = true">Hair</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'/wido-beauty'" (click)="isMenuCollapsed = true">Beauty</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'/wido-shooting'" (click)="isMenuCollapsed = true">Production</a>
        </li>
       
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'gallery'" (click)="isMenuCollapsed = true" >Gallery</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" [routerLink]="'/page/wido-contatti'" (click)="isMenuCollapsed = true" >Contatti</a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" href="https://www.facebook.com/widostudiopavia" target="_blank" (click)="isMenuCollapsed = true"><img class="img-fluid w-social" src="/assets/img/wido-facebook.png"></a>
        </li>
        <li style=" background-color: rgba(248, 248, 255, 0.692);" >
          <a class="text-white" href="https://www.instagram.com/wido_studio/" target="_blank" (click)="isMenuCollapsed = true"><img class="img-fluid w-social" src="/assets/img/wido-instagram.png"></a>
        </li>
      </ul>
  </div>

</div>
</div>


<!-- <div style="text-align: -webkit-center; font-family: 'Lato', sans-serif !important;font-size: 1.9vh;">
  <a class="navbar-brand m-0 pt-5" href=" ">
    <img src="assets/img/wido-logo.png" class="img-fluid w-50">
</a>
<div class="do my-4">
  <div class="bar"></div>
  <div class="bar"></div>
  <div class="bar"></div>
 <ul style="padding: 0;">
  <li  >
    <a class="text-white" [routerLink]="'/wido-studio'">Studio</a>
  </li>
  <li >
    <a class="text-white" [routerLink]="'/page/staff'"  >Staff</a>
  </li>
  <li >
    <a class="text-white" [routerLink]="'/wido-hair'" >Hair</a>
  </li>
  <li  >
    <a class="text-white" [routerLink]="'/wido-beauty'" >Beauty</a>
  </li>
  <li  >
    <a class="text-white" [routerLink]="'/wido-shooting'" >Production</a>
  </li>
  <li >
    <a class="text-white" [routerLink]="'gallery'"  >Gallery</a>
  </li>
  <li  >
    <a class="text-white" [routerLink]="'/page/wido-contatti'"  >Contatti</a>
  </li>
  <li style="width:50px;">
    <a class="text-white " href="https://www.facebook.com/widostudiopavia" target="_blank" ><img class="img-fluid w-50  "  src="/assets/img/wido-facebook.png"></a>
  </li>
  <li style="width:50px;" >
    <a class="text-white " href="https://www.instagram.com/wido_studio/" target="_blank"><img class="img-fluid w-50 "  src="/assets/img/wido-instagram.png"></a>
  </li>
 </ul>
</div>
</div> -->


