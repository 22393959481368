import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wido-contatti',
  templateUrl: './wido-contatti.component.html',
  styleUrls: ['./wido-contatti.component.css']
})
export class WidoContattiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
