<section class="my-5 pt-5 mx-3  body">
<div class="row m-0">
<div class="col-lg-5 m-2">
    <img src="/assets/img/wido-pavia-1.jpg" class="img-fluid" alt="image">
</div>
<div class="col-lg-6 m-2 mb-5">
    <img src="/assets/img/wido-pavia-3.jpg" class="img-fluid" alt="image">
</div>
<div class="col-lg-8 m-2 mb-5">
    <img src="/assets/img/wido-pavia-2.jpg" class="img-fluid" alt="image">
</div>
<div class="col-lg-3 m-2 mt-5">
    <img src="/assets/img/wido-pavia-4.jpg" class="img-fluid" alt="image">
</div>
<div class="col-lg-4 m-2">
    <img src="/assets/img/wido-pavia-7.jpg" class="img-fluid" alt="image">
</div>
<div class="col-lg-5 m-2 mt-5">
    <img src="/assets/img/wido-pavia-5.jpg" class="img-fluid" alt="image">
</div>

<div class="col-lg-4 m-2">
    <img src="/assets/img/wido-pavia-9.jpg" class="img-fluid" alt="image">
</div>

<div class="col-lg-4 m-2">
    <img src="/assets/img/wido-pavia-8.jpg" class="img-fluid" alt="image">
</div>
</div>
</section>
