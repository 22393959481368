

<div class="carousel ">
    <ul class="panes">
        <li>
            <img src="/assets/img/homepage/Wido-studio-1.png" alt="wido studio" class=" img-fluid" style="width:100%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-studio-2.png" alt="wido studio" class=" img-fluid"  style="width:100%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-studio-3.png" alt="wido studio" class=" img-fluid" style="width:100%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-studio-4.png" alt="wido studio" class=" img-fluid" style="width:100%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-studio-5.png" alt="wido studio" class=" img-fluid"  style="width:100%; padding-top: 5%;">
        </li>
    
    </ul>
    
</div>

