<div class="body" style="padding-top: 15%; padding-bottom: 15%;">
    <section class="container text-dark  lead  " >
        <div class="row">
        <div class="col-lg-5 mt-3">
            <h1 class="thick">CUT & STYLING (da €55)</h1>
            <p class="thin ">
                Dare forma rispettando il naturale movimento di capelli è il principio base del metodo di taglio Wido Studio.<br><br> Gli stilisti del nostro salone progettano il taglio e lo styling, valorizzando i canoni estetici dei clienti e garantendo un’ottima resa anche a casa.
    
            </p>

        </div>
        <div class="col-lg-7">       
        </div>
    </div>
        <div class="row">
            <div class="col-lg-7">       
            </div>
        <div class="col-lg-5">
            <h1 class="thick">COLOR & GLOSS (da €50)</h1>
            <p class="thin">
                Questa tecnica consiste nell’abbinare varie tonalità di colore per un risultato naturale.<br><br> È ideale per nascondere i capelli bianchi o ravvivare un vecchio colore ormai spento con un effetto multi-sfaccettato e brillante.<br><br> Gli stilisti di Wido Studio sapranno consigliare la tonalità di colore più adatta in base alle cromie del capello.
            </p>
        </div>
    </div>
    </section>

    <section class="container text-dark  lead " >
        <div class="row">
        <div class="col-lg-5">
            <h1 class="thick">BALAYAGE (€80)</h1>
            <p class="thin ">
                Tecnica di schiaritura che permette di avere effetti sia naturali che audaci.<br><br> Grazie all’esecuzione a mano libera, il colore avrà un effetto sfumato e personalizzato dagli stilisti di Wido Studio.
            </p>
        </div>
        <div class="col-lg-7">       
        </div>
    </div>
        <div class="row">
            <div class="col-lg-7"> 
                 
            </div>
        <div class="col-lg-5">
            <h1 class="thick">HAIR CONSULTATION</h1>
            <p class="thin">
                Una vera e propria consulenza di immagine.<br><br> Gli stilisti di Wido Studio, attraverso l’uso di immagini d’ispirazione e analizzando le cromie e la fisionomia del viso, sapranno consigliare il taglio e il colore più adatto alla cliente per aiutarla nella scelta del proprio hair style.
            </p>
        </div>
    </div>
    </section>
    
  
    </div>
