<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="nyYxZ18j"></script>
    <div class="fb-share-button btn btn-dark px-3  " mat-button data-href="https://widostudio.com/page/wido-contatti" data-layout="button_count" data-size="large"><a target="_blank"  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fpage%2Fwido-contatti&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore text-white " style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->

<div class="body ">
   
    <div class="container pt-4 mt-4 ">
<div class="row text-center pb-5 mb-5">
    <div class=" col-lg-6 mb-4">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2812.0826890338035!2d9.15356931620065!3d45.18542236002149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478726451580caa1%3A0x5b697e9cba736255!2sWido%20studio!5e0!3m2!1sit!2sit!4v1631872118588!5m2!1sit!2sit"
            width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div class="col-lg-6 px-5">
        <img class="img-fluid px-5 " src="/assets/img/orari-wido-studio-pavia-ww.png">
    </div>
 
</div>
</div>
<div class="container text-center pb-5">
    <div class="col-lg-12">
        <h1 class="thick text-uppercase my-5 py-3" style="font-size: 3vh; font-weight: 100;">Contatti</h1>
        <hr>
        <p class="thin" style="font-size: 2.5vh; line-height: 3vh;">
           <a class="text-dark" href="tel:+393407296309">340 7296309</a>
        </p>
        <hr>
        <p class="thin" style="font-size: 2.5vh; line-height: 3vh;">
           <a class="text-dark" href="mailto:info@widostudio.com"> info@widostudio.com</a>
        </p>
        <hr>
        <div class="row text-center mb-5 " style="font-size: 2vh; line-height: 3vh;place-content: center;">
           <div class="col-lgl-4 mx-2 w-social"><a href="https://www.facebook.com/widostudiopavia" target="_blank"><img class="img-fluid" src="/assets/img/wido-facebook-b.png"></a></div>
           <div class="col-lgl-4 mx-2 w-social"><a href="https://www.instagram.com/wido_studio/" target="_blank"><img class="img-fluid" src="/assets/img/wido-instagram-b.png"></a></div>
        </div>
    </div>
</div>
</div>
<!-- 
<div class="col-lg-12">
    <h1 class="thick text-uppercase my-5 py-5" style="font-size: 3vh; font-weight: 100;">Orari</h1>
    <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
       <b>Lunedì</b> | Chiuso
    </p>
    <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
        <b>Martedì</b> | 10-19
     </p>
    <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
        <b>Mercoledì</b> | 10-19
     </p>
     <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
        <b>Giovedì</b> | 13-21
     </p>
     <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
        <b>Venerdì</b> | 10-19
     </p>
     <hr>
    <p class="thin" style="font-size: 2vh; line-height: 3vh;">
        <b>Sabato</b> | 10-18
     </p>
     <hr>
    <p class="thin pb-5 mb-5" style="font-size: 2vh; line-height: 3vh;">
        <b>Domenica</b> | Chiuso
     </p>
</div> -->