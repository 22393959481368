<div class="carousel ">
    <ul class="panes">
        <li>
            <img src="/assets/img/homepage/Wido-hair-1.png" alt="wido hair" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-hair-2.png" alt="wido hair" class=" img-fluid"  style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-hair-3.png" alt="wido hair" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-hair-4.png" alt="wido hair" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li class="text-center" style="justify-content: center;">
            <img src="/assets/img/homepage/Wido-hair-1.png" alt="wido hair" class=" img-fluid"  style="width:90%; padding-top: 5%;">
        </li>
    
    </ul>
    
</div>