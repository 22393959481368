<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="BPPQkIEw"></script>
    <div class="fb-share-button btn btn-dark px-3 text-white" mat-button  data-href="https://widostudio.com/wido-hair" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fwido-hair&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore text-white" style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->


<div class="body">
<section class="container lead  " style="margin-bottom: 10%;" >
    <!-- <div class="row">
        <div class="col-lg-5 mt-3">
            <h1 class="thick text-uppercase">CUT & STYLING  <span style="font-size: 1.1rem; font-weight: 100;">(da €55)</span></h1>
            <p class="thin ">
                Dare forma rispettando il naturale movimento di capelli è il principio base del metodo di taglio Wido Studio.<br><br> Gli stilisti del nostro salone progettano il taglio e lo styling, valorizzando i canoni estetici dei clienti e garantendo un’ottima resa anche a casa.<br><br>
                <span  style="font-weight: 900;">Tempo</span> : 60 min
    
            </p>

        </div>
    <div class="col-lg-7">       
    </div>
</div> -->

        <div class="col-lg-12 text-center my-5 py-5">
            <h1 class="thick text-uppercase">CUT & STYLING </h1>
            <p class="thin ">
                Dare risalto alle unicità di ogni volto, rispettando la linea naturale del capello. Questa è la filosofia dietro ogni taglio realizzato da Wido Studio. <br><br>
                I nostri stylists studiano attentamente il taglio, il colore e la piega migliori per valorizzare il singolo cliente, sia in salone che a casa loro. 
                <br><br>
                <span  style="font-weight: 900;">Tempo: 60 min</span> <br><br>
                <span  style="font-weight: 900;">Costo: da €60</span>
            </p>
        </div>

        <div class=" col-lg-12 text-center my-5 py-5">
            <h1 class="thick text-uppercase">COLOR & GLOSS  </h1>
            <p class="thin">
                Che sia per ravvivare il colore o per cambiare totalmente look, questa tecnica garantisce un risultato 
                naturale grazie all’abbinamento di varie tonalità di colori. <br><br> 
                I colorists sapranno consigliare al meglio il cliente a seconda delle cromie del capello. 
                
                <br><br> <span  style="font-weight: 900;">Tempo: 90 min</span> <br><br>
                <span  style="font-weight: 900;">Costo: da €50</span>
            </p>
        </div>

</section>
<div class="row m-0  ">
<div class="col-lg-6" style="text-align: -webkit-center;">
	<div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-hair-pavia-1.jpg" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">VARIO</span>
			<div class="icon">
				   
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-hair-pavia-1.jpg" alt="info image">
			</div>
		</div>
		
	</div>
</div>
<div class="col-lg-6" style="text-align: -webkit-center;"> 
    <div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-hair-pavia-2.jpg" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">INTENSO</span>
			<div class="icon">
				   
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-hair-pavia-2.jpg" alt="info image">
			</div>
		</div>
		
	</div>      
</div>
</div>
<section class="container lead pb-5 ">

        <div class=" col-lg-12 text-center  pb-5">
            <h1 class="thick text-uppercase">BALAYAGE </h1>
            <p class="thin ">
                Noto anche come tecnica di colorazione a mano libera, consiste nello schiarire ciocche di capelli di due o tre altezze di tono per ottenere un aspetto molto naturale. <br><br> 

I colorists del salone saranno in grado di personalizzare le sfumature in base alle richieste del cliente. 

                <br><br> <span  style="font-weight: 900;">Tempo:  da 120 min  minuti (varia a seconda della tipologia di capello e alla sua lunghezza)</span> <br><br>
                <span  style="font-weight: 900;">Costo: da €80</span>
            </p>
        </div>

        <div class=" col-lg-12 text-center my-5 py-5">
            <h1 class="thick text-uppercase">HAIR CONSULTATION</h1>
            <p class="thin">
                Prima di un cambio look totale o di un semplice taglio, i nostri stylists e colorists sono disponibili per fornire una consulenza di immagine gratuita.<br><br>

Durante la consulenza, il nostro team sarà in grado di consigliare il taglio e/o il colore più adatto alla fisionomia del volto del cliente, attraverso l’uso di immagini d’ispirazione. 

                <br><br> <span  style="font-weight: 900;">Durata:  15 min</span> <br><br>
                <span  style="font-weight: 900;">Costo: gratuito</span>
            </p>
        </div>

</section>

<div class="row m-0  ">
    <div class="col-lg-6 " style="text-align: -webkit-center;">
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-hair-pavia-3.jpg" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text">SPAVALDO</span>
                <div class="icon">
                
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-hair-pavia-3.jpg" alt="info image">
                </div>
            </div>
            
        </div>
    </div>
    <div class="col-lg-6" style="text-align: -webkit-center;"> 
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-hair-pavia-4.jpg" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text">ORIGINALE</span>
                <div class="icon">
                
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-hair-pavia-4.jpg" alt="info image">
                </div>
            </div>
            
        </div>      
    </div>
    </div>
</div>