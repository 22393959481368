import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curtain-shooting',
  templateUrl: './curtain-shooting.component.html',
  styleUrls: ['./curtain-shooting.component.css']
})
export class CurtainShootingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
