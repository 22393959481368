import { Component, OnInit } from '@angular/core';
import { FunzioniLayout } from 'src/app/shared/FunzioniLayout';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  constructor(private funzioniLayout:FunzioniLayout) { }

  ngOnInit(): void {
    this.funzioniLayout.gotoTopPage();
  }

}
