import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FunzioniLayout } from './shared/FunzioniLayout';
import { filter, map, mergeMap} from 'rxjs/operators';
import { SEOService } from './_services/seo.service';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'WidoStudio';
  isShow: boolean;
    topPosToStartShowing = 100;
    
  //Serve per gestire la freccia che riporta in su la pagina
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;


    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }

  }
  
  constructor(private funzioniLayout:FunzioniLayout, private router:Router, private seoService:SEOService,private activatedRoute:ActivatedRoute){
    const navEndEvents = router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: NavigationEnd) => { 
      this.funzioniLayout.gotoTopPage();
    })

    
  }

  ngOnInit(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       this.seoService.updateTitle(event['title']);       
       this.seoService.updateOgUrl(event['ogUrl']);
       this.seoService.updateOgImage(event['ogImage']);       
       this.seoService.updateOgTitle(event['title']);
       this.seoService.updateOgDescription(event['description']);
       //Updating Description tag dynamically with title
       this.seoService.updateDescription(event['description'])       
       
     }); 
  }

  gotoTop(){
    this.funzioniLayout.gotoTopPage();
  }
}

