import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wido-hair',
  templateUrl: './wido-hair.component.html',
  styleUrls: ['./wido-hair.component.css']
})
export class WidoHairComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
