import { SEOService } from './_services/seo.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavBarComponent } from './component/nav-bar/nav-bar.component';
import { FooterComponent } from './component/footer/footer.component';
import { ScrittaNeonComponent } from './component/scritta-neon/scritta-neon.component';
import { HomepageComponent } from './page/homepage/homepage.component';
import { CurtainComponent } from './component/curtain/curtain.component';
import { CurtainShootingComponent } from './component/curtain-shooting/curtain-shooting.component';
import { CurtainStudioComponent } from './component/curtain-studio/curtain-studio.component';
import { CurtainBeautyComponent } from './component/curtain-beauty/curtain-beauty.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WidoStudioComponent } from './page/wido-studio/wido-studio.component';
import { GalleryWidoStudioComponent } from './component/gallery-wido-studio/gallery-wido-studio.component';
import { WidoShootingComponent } from './page/wido-shooting/wido-shooting.component';
import { WidoHairComponent } from './page/wido-hair/wido-hair.component';
import { WidoBeautyComponent } from './page/wido-beauty/wido-beauty.component';
import { GalleryComponent } from './page/gallery/gallery.component';
import { HairstyleWidoServiziComponent } from './page/hairstyle-wido-servizi/hairstyle-wido-servizi.component';
import { StaffComponent } from './page/staff/staff.component';
import { WidoContattiComponent } from './page/wido-contatti/wido-contatti.component';
import { GalleryWidoHairComponent } from './component/gallery-wido-hair/gallery-wido-hair.component';
import { GalleryWidoBeautyComponent } from './component/gallery-wido-beauty/gallery-wido-beauty.component';
import { GalleryWidoProductionComponent } from './component/gallery-wido-production/gallery-wido-production.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    ScrittaNeonComponent,
    HomepageComponent,
    CurtainComponent,
    CurtainShootingComponent,
    CurtainStudioComponent,
    CurtainBeautyComponent,
    WidoStudioComponent,
    GalleryWidoStudioComponent,
    WidoShootingComponent,
    WidoHairComponent,
    WidoBeautyComponent,
    GalleryComponent,
    HairstyleWidoServiziComponent,
    StaffComponent,
    WidoContattiComponent,
    GalleryWidoHairComponent,
    GalleryWidoBeautyComponent,
    GalleryWidoProductionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatIconModule
  ],
  providers: [SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
