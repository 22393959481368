<div class="carousel ">
    <ul class="panes">
        <li>
            <img src="/assets/img/homepage/Wido-beauty-1.png" alt="wido beauty" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-beauty-2.png" alt="wido beauty" class=" img-fluid"  style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-beauty-3.png" alt="wido beauty" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-beauty-4.png" alt="wido beauty" class=" img-fluid" style="width:90%; padding-top: 5%;">
        </li>
        <li>
            <img src="/assets/img/homepage/Wido-beauty-1.png" alt="wido beauty" class=" img-fluid"  style="width:90%; padding-top: 5%;">
        </li>
    
    </ul>
    
</div>