<div class="bg-black  pb-5 pt-5 ">
<div class=" font-d-2 font-light  text-center text-white p-5  ">
  <br><br><br>
    <span id="W">W</span>
    <span id="I">I</span>
    <span id="D">D</span>
    <span id="O">O</span>
    <span id="_">_</span>
    <span id="S">S</span>
    <span id="T">T</span>
    <span id="U">U</span>
    <span id="D">D</span>
    <span id="I">I</span>
    <span id="O">O</span>
  </div>
</div>
