<div class="curtain" id="some-div">
    <div class="curtain-wrapper" >
      <input  type="checkbox"   checked>
      <div class="curtain__panel curtain__panel--left center" data-title="tap..."  ><span id="some-element" class="text-right tap "><div class="pulse"></div></span><img class="img-fluid" src="assets/img/wido-studio-def-1.png"> </div>
      
      <div class="curtain__prize bg-black text-center " style="text-align: webkit-center;" >
        
        <div class="curtain__prize bg-black" >
        
          <app-gallery-wido-studio></app-gallery-wido-studio>
 
        </div>
        
      </div>
      <div class="curtain__panel curtain__panel--right center-start" data-title="tap..."><span id="some-element-2" class="text-left  tap"></span><img class="img-fluid" src="assets/img/wido-studio-def-2.png"></div>
    </div>
  </div>

  <!-- <div class="container middled ">  -->