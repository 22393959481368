<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="D6TDJ52U"></script>
    <div class="fb-share-button btn btn-dark px-3  " mat-button  data-href="https://widostudio.com/wido-shooting" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fwido-shooting&amp;src=sdkpreparse"  class="fb-xfbml-parse-ignore text-white " style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->

<div class="body">
<section class="container     lead  pb-5" style="margin-bottom: 10%;" >
  
    <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">IMMAGINE</h1>
        <p class="thin ">
            L’immagine di Wido Studio si sviluppa a 360 gradi. Tutto è iniziato grazie a una collaborazione con uno studio fotografico di Pavia che lavorava anche all’estero, attraverso il quale Wido ha effettuato le prime sperimentazioni. 
          <br><br>  Questo ha dato il via alla sua curiosità nei confronti della parte di sviluppo dell’immagine di un salone, partendo dallo definizione di uno stile proprio, fino alla gestione professionale della comunicazione visiva, attraverso la fotografia e il setting. 
            
        </p>
    </div>

        <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">COLLEZIONI</h1>
        <p class="thin">
            Wido Studio ha iniziato a svolgere lavori per le grandi aziende, tra cui L’Oreal, Wella Italia e altri brand del settore. Inoltre ha curato l’intera immagine di una collezione di tagli e colori di uno dei brand sopracitati di prodotti per capelli, dalla ricerca delle tendenze, sino alla realizzazione dello shooting e alla sfilata finale. 
<br><br>Grazie a questi progetti, Wido Studio ha acquisito notorietà e prestigio. 
      
        </p>
    </div>

</section>
<div class="row m-0  ">
<div class=" col-lg-6" style="text-align: -webkit-center;">
	<div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-shooting-pavia-1.jpg" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">ESPLICITO</span>
			<div class="icon">
				     
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-shooting-pavia-1.jpg" alt="info image">
			</div>
		</div>
		
	</div>
</div>
<div class="col-lg-6" style="text-align: -webkit-center;"> 
    <div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-shooting-pavia-2.jpg" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">SENSUALE</span>
			<div class="icon">
				     
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-shooting-pavia-2.jpg" alt="info image">
			</div>
		</div>
		
	</div>      
</div>
</div>
<section class="container    pb-5  mb-5 lead  " >
 
    <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">WIDO PRODUCTION</h1>
        <p class="thin ">
            La maturità lavorativa raggiunta durante gli anni di pratica ha permesso oggi a Wido di realizzare produzioni autonome a nome esclusivo Wido Production. Uno dei progetti più interessanti in progress prende vita proprio all’interno del salone Wido Studio, dove vengono selezionati alcuni clienti per essere trasformati nei nostri modelli. 
          <br><br>  Scegliamo i volti più originali che potrebbero creare tendenza e ispirazione tra i loro coetanei e non. 
            
            
        </p>
    </div>


 

    <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">CANONI</h1>
        <p class="thin ">
            Noi non ricerchiamo modelli dalla bellezza plastica, finta, convenzionale. <br>
Noi individuiamo i volti più iconici, con delle peculiarità uniche, riconoscibili, in cui le persone possano rivedersi e cercare un modello. <br><br>
La salute dei capelli è certamente imprescindibile, per poter applicare al meglio il taglio e il colore per lo shooting. 

            
        </p>
    </div>

</section>

<div class="row m-0  ">
    <div class="col-lg-6" style="text-align: -webkit-center;">
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-shooting-pavia-3.jpg" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text">UNICO</span>
                <div class="icon">
                      
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-shooting-pavia-3.jpg" alt="info image">
                </div>
            </div>
            
        </div>
    </div>
    <div class="col-lg-6" style="text-align: -webkit-center;"> 
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-shooting-pavia-4.jpg" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text TEXT-UPPERCASE">NUOVO</span>
                <div class="icon">
                      
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-shooting-pavia-4.jpg" alt="info image">
                </div>
            </div>
            
        </div>      
    </div>
    </div>

</div>