import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-wido-hair',
  templateUrl: './gallery-wido-hair.component.html',
  styleUrls: ['./gallery-wido-hair.component.css']
})
export class GalleryWidoHairComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
