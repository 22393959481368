import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-wido-beauty',
  templateUrl: './gallery-wido-beauty.component.html',
  styleUrls: ['./gallery-wido-beauty.component.css']
})
export class GalleryWidoBeautyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
