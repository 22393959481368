<!-- <div class="text-center py-5">
    <div id="fb-root"></div>
    <script async defer crossorigin="anonymous" src="https://connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v12.0" nonce="0q1tUavv"></script>
    <div class="fb-share-button btn btn-dark px-3  " mat-button  data-href="https://widostudio.com/wido-studio" data-layout="button_count" data-size="large"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwidostudio.com%2Fwido-studio&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore text-white " style="text-decoration: none;"><img class="img-fluid w-10" src="/assets/img/wido-facebook.png">Condividi</a></div>
</div> -->

<section class="container   lead e pb-5" style=" margin-bottom: 10%;" >
  
    <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">WIDO STUDIO</h1>
        <p class="thin ">
            Fondato nel 2008 da un’idea di Guido Malaguri, in arte Wido. Il suo stile innovativo ha fatto sì che lo Studio fosse subito conosciuto a Pavia e il suo taglio riconosciuto per le vie della città. <br>

Formatosi in prestigiose accademie internazionali, come la Sasson Academy di Londra, l’Accademia L’Oreal e il Wella Studio Italia, diventando poi stilista per queste ultime, Wido è in costante aggiornamento per garantire alla sua clientela la massima professionalità. <br><br>

Wido ha creato un nuovo concetto di hair salon, dove il cliente è immerso nel design. <br>
Il salone è situato in un elegante appartamento, dai soffitti affrescati, nel centro storico della città. <br>
La cura ai dettagli si respira in ogni particolare attentamente selezionato.  <br><br>

Entrare da Wido Studio significa mettere piede in casa di Wido stesso: un luogo dove si è accolti come da un amico. <br>

Ogni membro del team di stilisti acconciatori è stato scelto accuratamente da Wido per le peculiarità differenti di ognuno, al fine di ricreare un ambiente ricco di stimoli creativi unici. 

        </p>
    </div>


<div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">SERVIZI</h1>
        <p class="thin">
            In aggiunta al principale servizio di hairstyling, Wido Studio è affiancato da Wido Beauty: un centro estetico dove vengono eseguiti svariati trattamenti, tra cui nail art, laminazione ciglia e sopracciglia. <br>

            A completare l’identità del salone, è stata ideata una linea di prodotti per la cura del capello, a marchio Wonder. <br><br>
            
            Infine vi è Wido Production, un servizio per la realizzazione di set fotografici e cortometraggi. <br>
            
            Il mondo creativo di Wido racchiude molteplici forme artistiche che si arricchiscono l’una con l’altra. <br>
            Sono questi dettagli a rendere un semplice appuntamento in salone, una vera e propria esperienza  a 360 gradi. <br><br>
            
            Stilying, fotografia, design, cinema, mostre, eventi culturali e non. <br><br>
            
            La ricerca della bellezza in ogni sua sfaccettatura è la mission dell’intero team. <br>
            Bellezza intesa nel senso più puro del termine, affinché ciascun cliente sia valorizzato nelle proprie singolarità ed esca dal salone appagato dalla sua immagine. 
            
        </p>
    </div>

</section>
<div class="row m-0  ">
<div class=" col-lg-6" style="text-align: -webkit-center;">
	<div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-man.png" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">SACRO</span>
			<div class="icon">
				
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-man.png" alt="info image">
			</div>
		</div>
		
	</div>
</div>
<div class="col-lg-6" style="text-align: -webkit-center;"> 
    <div class="card">
		<div class="card--image">
			<img src="/assets/img/wido-studio-pavia-1.png" alt="image">
		</div>
		<div class="card--info info--1">
			<span class="info--text">INTIMO</span>
			<div class="icon">
			
			</div>
			<div class="info--image">
				<img src="/assets/img/wido-studio-pavia-1.png" alt="info image">
			</div>
		</div>
		
	</div>      
</div>
</div>
<section class="container   mt-5 lead  pb-3" style=" margin-bottom: 15%;">

        <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">PERSONALITà</h1>
        <p class="thin ">
            “La gran parte dei saloni è incentrata sulla figura del direttore. Così, ho impostato anche io Wido Studio in questa maniera. Finché non ho realizzato che più punti di vista, avrebbero potuto ampliare la visione stilistica dell’intero salone. Per questo ho scelto di avere al mio fianco professionisti unici”

        </p>
    </div>
 

    <div class="col-lg-12 text-center py-5">
        <h1 class="thick text-uppercase">STAFF</h1>
        <p class="thin">
            “Il mio team è formato in totale da 8 professionisti del settore, divisi in 4 stylists e colorists, un’estetista, un make up artist, una fotografa e una receptionist. <br>
            La peculiarità di Wido Studio risiede nella formazione ad personam: il che significa che ogni membro del team è incoraggiato a trovare e perseguire la propria strada stilistica, sotto la mia supervisione. Questa garantisce di seguire un fil rouge di stile, per mantenere una brand identity solida” 
            
        </p>
    </div>

</section>

<div class="row m-0  ">
    <div class=" col-lg-6" style="text-align: -webkit-center;">
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-studio-pavia-2.png" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text ">SFUGGENTE</span>
                <div class="icon">
                
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-studio-pavia-2.png" alt="info image">
                </div>
            </div>
            
        </div>
    </div>
    <div class="col-lg-6" style="text-align: -webkit-center;"> 
        <div class="card">
            <div class="card--image">
                <img src="/assets/img/wido-studio-pavia-3.png" alt="image">
            </div>
            <div class="card--info info--1">
                <span class="info--text">SUBLIME</span>
                <div class="icon">
                
                </div>
                <div class="info--image">
                    <img src="/assets/img/wido-studio-pavia-3.png" alt="info image">
                </div>
            </div>
            
        </div>      
    </div>
    </div>