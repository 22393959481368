import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scritta-neon',
  templateUrl: './scritta-neon.component.html',
  styleUrls: ['./scritta-neon.component.css']
})
export class ScrittaNeonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
