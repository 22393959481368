import { StaffComponent } from './page/staff/staff.component';
import { HairstyleWidoServiziComponent } from './page/hairstyle-wido-servizi/hairstyle-wido-servizi.component';
import { GalleryComponent } from './page/gallery/gallery.component';
import { WidoBeautyComponent } from './page/wido-beauty/wido-beauty.component';
import { WidoHairComponent } from './page/wido-hair/wido-hair.component';
import { WidoShootingComponent } from './page/wido-shooting/wido-shooting.component';
import { WidoStudioComponent } from './page/wido-studio/wido-studio.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './page/homepage/homepage.component';
import { WidoContattiComponent } from './page/wido-contatti/wido-contatti.component';

const routes: Routes = [
  {path:'', component:HomepageComponent, data:{title:'Wido Studio Pavia', description:'Wido Studio nasce nel 2008 dall’idea del suo fondatore Wido e si fa subito conoscere nella città di Pavia per la sua originalità', ogUrl:'https://widostudio.com/', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-studio.png'}},
  {path:'wido-studio', component:HomepageComponent, data:{title:'Wido Studio Pavia', description:'Situato in un elegante appartamento nel centro di Pavia, ricrea un luogo rilassante e riservato', ogUrl:'https://widostudio.com/wido-studio', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-studio.png'}},
  {path:'wido-shooting', component:HomepageComponent, data:{title:'Wido Studio | Production', description:'Wido Production inizia con L’Oreàl, Wella e altri brand del settore, oggi tutte le produzioni che realizziamo sono esclusivamente interne.', ogUrl:'https://widostudio.com/wido-shooting', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-production.png'}},
  {path:'wido-hair', component:HomepageComponent, data:{title:'Wido Studio | Hairstyle', description:'Gli stilisti del nostro salone progettano il taglio e lo styling, valorizzando i canoni estetici dei clienti e garantendo un’ottima resa anche a casa', ogUrl:'https://widostudio.com/wido-hair', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-hairstyle.png'}},
  {path:'wido-beauty', component:HomepageComponent, data:{title:'Wido Studio | Beauty', description:'Wido Studio offre anche trattamenti estetici tra cui: laminazione ciglia, trattamenti sopracciglia, ricostruzione unghie e semipermanente', ogUrl:'https://widostudio.com/wido-beauty', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-estetista.png'}},
  {path:'gallery', component:HomepageComponent, data:{title:'Wido Studio | Gallery', description:'', ogUrl:'https://widostudio.com/gallery', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-gallery.png'}},
  {path:'page/staff', component:HomepageComponent, data:{title:'Wido Studio | Staff', description:'Il team è formato da un gruppo affiatato di stiliste, ognuna con le proprie skills e sempre al passo con le nuove tendenze.', ogUrl:'https://widostudio.com/page/staff', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-staff.png'}},
  {path:'page/wido-contatti', component:HomepageComponent, data:{title:'Wido Studio | Contatti', description:'Contattaci per prendere appuntamento e goderti del tempo solo per te', ogUrl:'https://widostudio.com/page/wido-contatti', ogImage:'https://widostudio.com/assets/img/social/wido-parrucchiere-pavia-contatti.png'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
