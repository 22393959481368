<!-- 
<div id="studio" >
    <app-curtain-studio></app-curtain-studio>
</div>
<section class="container relative">
    <a [routerLink]="'/wido-studio'" style="color: black;">
<div class="row p-5 m-0 " style="justify-content: center;">
    <div class=" s mx-5 py-4 ">          
        <span style="font-weight: lighter;">wido</span><span class="thick">studio<br></span>      
    </div>

</div>
</a>
</section>


<div id="hair" >
    <app-curtain></app-curtain>
</div>

<section class="container relative">
    <a [routerLink]="'/wido-hair'" style="color: black;">
    <div class="row p-5 m-0 " style="justify-content: center;">
        <div class="s mx-5 py-4 ">          
            <span  style="font-weight: lighter;">wido</span><span class="thick">hair<br></span>      
        </div>
    </div>
</a>
    </section>

<div id="beauty" >
    <app-curtain-beauty></app-curtain-beauty>
</div>
<section class="container relative">
    <a [routerLink]="'/wido-beauty'" style="color: black;">
    <div class="row p-5 m-0 " style="justify-content: center;">
        <div class="s mx-5 py-4 ">          
            <span  style="font-weight: lighter;">wido</span><span class="thick">beauty<br></span>      
        </div>
     </div>
    </a>
    </section>
    <div id="shooting" >
        <app-curtain-shooting></app-curtain-shooting>
     </div>
     <section class="container relative">
        <a [routerLink]="'/wido-shooting'" style="color: black;">
        <div class="row p-5 m-0 " style="justify-content: center;">
            <div class="s mx-4 py-4 ">          
                <span  style="font-weight: lighter;">wido</span><span class="thick">production<br></span>      
            </div>
        </div>
        </a>
        </section>
        -->

        <div class="text-center my-5">
            <h1 style="font-size: 3rem;">Sito in manutenzione</h1>
        </div>
